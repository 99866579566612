<template>
  <div class="bill-container">
    <my-nav-bar
      :title="type === 'settlement' ? '账单结算' : '账单明细'"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <div v-if="bill" class="bill-content">

        <van-cell-group title="账单明细">
          <van-cell title="订单总额" :value="'￥' + bill.amount" />
          <van-cell v-if="depositAmount < 0" title="扣减定金" :value="'￥' + depositAmount" />
          <van-cell v-if="bill.red_packet_amount < 0" title="扣减红包" :value="'￥' + bill.red_packet_amount" />
          <van-cell v-if="bill.coupon_amount < 0" title="扣减优惠券" :value="'￥' + bill.coupon_amount" />
          <van-cell v-if="bill.return_amount < 0" title="扣减退货" :value="'￥' + bill.return_amount" />
          <van-cell v-if="bill.unpaid_amount < 0" title="扣减签单" :value="'￥' + bill.unpaid_amount" />
          <van-cell v-if="bill.decimal_amount < 0" title="扣减尾数" :value="'￥' + bill.decimal_amount" />
          <van-cell v-if="bill.expense_amount < 0" title="报销(额外计算)" :value="'￥' + bill.expense_amount" />
          <van-cell title="应交" :value="'￥' + bill.real_amount" />
        </van-cell-group>

        <van-cell-group v-if="bill.unpaid_amount < 0" title="签单明细">
          <van-cell v-if="bill.unpaid_return_amount < 0" title="签单退货金额" :value="'￥' + bill.unpaid_return_amount" value-class="cell-amount-value" />
          <van-cell title="本次签单金额" :value="'￥' + bill.real_unpaid_amount" value-class="cell-amount-value" />
        </van-cell-group>

        <van-cell-group v-if="bill.wxpay_amount > 0 || bill.cash_amount != 0 || bill.trans_amount > 0" title="支付方式">
          <van-cell v-if="bill.wxpay_amount > 0" title="线上支付" :value="'￥' + bill.wxpay_amount" />
          <van-cell v-if="bill.cash_amount != 0" title="现金支付" :value="'￥' + bill.cash_amount" />
          <van-cell v-if="bill.trans_amount > 0" title="转账支付" :value="'￥' + bill.trans_amount" />
        </van-cell-group>

        <van-cell-group v-if="bill.wxpay_amount > 0" title="支付实收">
          <template>
            <van-cell title="线上支付实收" :value="'￥' + bill.payment_online_amount" is-link center @click="toPaymentOnlineView" />
          </template>
        </van-cell-group>
        <van-cell-group v-if="bill.deposit_wxpay_amount < 0 || bill.deposit_alipay_amount < 0 || bill.deposit_cash_amount < 0" title="预付定金">
          <van-cell v-if="bill.deposit_wxpay_amount < 0" value-class="amount" title="微信定金" center :value="`￥${-bill.deposit_wxpay_amount}`" />
          <van-cell v-if="bill.deposit_alipay_amount < 0" value-class="amount" title="支付定金" center :value="`￥${-bill.deposit_alipay_amount}`" />
          <van-cell v-if="bill.deposit_cash_amount < 0" value-class="amount" title="现金定金" center :value="`￥${-bill.deposit_cash_amount}`" />
        </van-cell-group>
      </div>
      <div v-if="type === 'settlement'" class="fixed-bottom-btn">
        <van-button :disabled="canNotSubmit" square block text="确认结算" @click="handleConfirm" />
      </div>
      <div v-else class="fixed-bottom-btn">
        <van-button square block text="支付明细" @click="toPaymentDetail" />
      </div>
    </div>
    <!-- <van-popup
      v-model="showDateChoose"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择账单日期"
        @confirm="handleConfirmDate"
        @cancel="showDateChoose = false"
      />
    </van-popup> -->

  </div>
</template>

<script>
import billApi from '@/api/bill'
import myNavBar from '../../components/my-nav-bar.vue'
// import { toDateString } from '@/utils'
export default {
  name: 'Bill',
  components: { myNavBar },
  data() {
    return {
      bill: null,
      type: this.$route.query.type,
      canNotSubmit: true
      // currentDate: new Date()
      // showDateChoose: false
    }
  },
  computed: {
    depositAmount() {
      return (Number(this.bill.deposit_wxpay_amount) +
          Number(this.bill.deposit_alipay_amount) +
          Number(this.bill.deposit_cash_amount)).toFixed(2)
    }
  },
  created() {
    this.getBill()
  },
  methods: {
    getBill() {
      this.beginLoad()
      if (this.type === 'settlement') {
        const params = {
          bill_reject_id: this.$route.query.bill_reject_id
        }
        billApi.settlement(params).then(res => {
          this.bill = res.data
          this.canNotSubmit = false
          this.endLoad()
        }).catch(() => {
          this.endLoad()
        })
      } else {
        const params = {
          bill_id: this.$route.query.bill_id
        }
        billApi.detail(params).then(res => {
          this.bill = res.data
          this.endLoad()
        }).catch(() => {
          this.endLoad()
        })
      }
    },
    toPaymentDetail() {
      this.$router.push({
        path: '/bill-payment-detail',
        query: {
          bill_id: this.bill.id
        }
      })
    },
    handleConfirm() {
      this.confirm('确定要结算账单吗？请确认账单数据是否正确，结算后将无法再修改!', '提示').then(() => {
        this.beginLoad()
        billApi.finish().then(res => {
          this.success(res.msg)
          this.$router.go(-2)
        })
      })
    },
    // handleConfirmDate(value) {
    //   this.currentDate = value
    //   const data = {
    //     date: toDateString(this.currentDate),
    //     bill_reject_id: this.$route.query.bill_reject_id
    //   }
    //   this.beginLoad()
    //   billApi.finish(data).then(res => {
    //     this.showDateChoose = false
    //     this.success(res.msg)
    //     this.$router.go(-2)
    //   })
    // },
    toPaymentOnlineView() {
      this.$router.push({
        path: '/bill-payment-online',
        query: {
          bill_id: this.type === 'detail' ? this.bill.id : undefined
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .bill-content {
    padding: 0 15px 65px 15px;
    .van-cell {
      &__value {
        color: #ee0a24;
      }
    }
  }
</style>
