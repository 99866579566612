var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bill-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": _vm.type === 'settlement' ? '账单结算' : '账单明细',
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.bill ? _c('div', {
    staticClass: "bill-content"
  }, [_c('van-cell-group', {
    attrs: {
      "title": "账单明细"
    }
  }, [_c('van-cell', {
    attrs: {
      "title": "订单总额",
      "value": '￥' + _vm.bill.amount
    }
  }), _vm.depositAmount < 0 ? _c('van-cell', {
    attrs: {
      "title": "扣减定金",
      "value": '￥' + _vm.depositAmount
    }
  }) : _vm._e(), _vm.bill.red_packet_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "扣减红包",
      "value": '￥' + _vm.bill.red_packet_amount
    }
  }) : _vm._e(), _vm.bill.coupon_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "扣减优惠券",
      "value": '￥' + _vm.bill.coupon_amount
    }
  }) : _vm._e(), _vm.bill.return_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "扣减退货",
      "value": '￥' + _vm.bill.return_amount
    }
  }) : _vm._e(), _vm.bill.unpaid_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "扣减签单",
      "value": '￥' + _vm.bill.unpaid_amount
    }
  }) : _vm._e(), _vm.bill.decimal_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "扣减尾数",
      "value": '￥' + _vm.bill.decimal_amount
    }
  }) : _vm._e(), _vm.bill.expense_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "报销(额外计算)",
      "value": '￥' + _vm.bill.expense_amount
    }
  }) : _vm._e(), _c('van-cell', {
    attrs: {
      "title": "应交",
      "value": '￥' + _vm.bill.real_amount
    }
  })], 1), _vm.bill.unpaid_amount < 0 ? _c('van-cell-group', {
    attrs: {
      "title": "签单明细"
    }
  }, [_vm.bill.unpaid_return_amount < 0 ? _c('van-cell', {
    attrs: {
      "title": "签单退货金额",
      "value": '￥' + _vm.bill.unpaid_return_amount,
      "value-class": "cell-amount-value"
    }
  }) : _vm._e(), _c('van-cell', {
    attrs: {
      "title": "本次签单金额",
      "value": '￥' + _vm.bill.real_unpaid_amount,
      "value-class": "cell-amount-value"
    }
  })], 1) : _vm._e(), _vm.bill.wxpay_amount > 0 || _vm.bill.cash_amount != 0 || _vm.bill.trans_amount > 0 ? _c('van-cell-group', {
    attrs: {
      "title": "支付方式"
    }
  }, [_vm.bill.wxpay_amount > 0 ? _c('van-cell', {
    attrs: {
      "title": "线上支付",
      "value": '￥' + _vm.bill.wxpay_amount
    }
  }) : _vm._e(), _vm.bill.cash_amount != 0 ? _c('van-cell', {
    attrs: {
      "title": "现金支付",
      "value": '￥' + _vm.bill.cash_amount
    }
  }) : _vm._e(), _vm.bill.trans_amount > 0 ? _c('van-cell', {
    attrs: {
      "title": "转账支付",
      "value": '￥' + _vm.bill.trans_amount
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.bill.wxpay_amount > 0 ? _c('van-cell-group', {
    attrs: {
      "title": "支付实收"
    }
  }, [[_c('van-cell', {
    attrs: {
      "title": "线上支付实收",
      "value": '￥' + _vm.bill.payment_online_amount,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": _vm.toPaymentOnlineView
    }
  })]], 2) : _vm._e(), _vm.bill.deposit_wxpay_amount < 0 || _vm.bill.deposit_alipay_amount < 0 || _vm.bill.deposit_cash_amount < 0 ? _c('van-cell-group', {
    attrs: {
      "title": "预付定金"
    }
  }, [_vm.bill.deposit_wxpay_amount < 0 ? _c('van-cell', {
    attrs: {
      "value-class": "amount",
      "title": "微信定金",
      "center": "",
      "value": "\uFFE5".concat(-_vm.bill.deposit_wxpay_amount)
    }
  }) : _vm._e(), _vm.bill.deposit_alipay_amount < 0 ? _c('van-cell', {
    attrs: {
      "value-class": "amount",
      "title": "支付定金",
      "center": "",
      "value": "\uFFE5".concat(-_vm.bill.deposit_alipay_amount)
    }
  }) : _vm._e(), _vm.bill.deposit_cash_amount < 0 ? _c('van-cell', {
    attrs: {
      "value-class": "amount",
      "title": "现金定金",
      "center": "",
      "value": "\uFFE5".concat(-_vm.bill.deposit_cash_amount)
    }
  }) : _vm._e()], 1) : _vm._e()], 1) : _vm._e(), _vm.type === 'settlement' ? _c('div', {
    staticClass: "fixed-bottom-btn"
  }, [_c('van-button', {
    attrs: {
      "disabled": _vm.canNotSubmit,
      "square": "",
      "block": "",
      "text": "确认结算"
    },
    on: {
      "click": _vm.handleConfirm
    }
  })], 1) : _c('div', {
    staticClass: "fixed-bottom-btn"
  }, [_c('van-button', {
    attrs: {
      "square": "",
      "block": "",
      "text": "支付明细"
    },
    on: {
      "click": _vm.toPaymentDetail
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }